import * as React from "react";

import "../../styles/servicios.css";
import Footer from "../../components/Footer/footer";
import { Navbar } from "../../components/Navbar/navbar";
import { Head } from "../../components/Header/head";
import RoomCard from "../../components/RoomCard/roomCard";
import { habitaciones } from "../../assets/habitaciones";
import { SSRProvider } from "react-bootstrap";
const Servicios = () => {
  return (
    <SSRProvider>
      <Head title="Posada Los Jerónimos - Alojamiento" />
      <Navbar />
      <section id="habitaciones">
        <h2 className="section-title">Posada Rural Los Jerónimos</h2>
        <p className="section-description">
          En la Posada Los Jerónimos contamos con una gran variedad de
          habitaciones para que disfruteis de vuestra estancia de la forma que
          mejor se adapte a vosotros.
        </p>
        <table>
          <tr>
            <th></th>
            <th>Dobles</th>
            <th>Triples</th>
            <th>Cuádruples</th>
          </tr>
          <tr className="t-baja">
            <td>Temporada Baja*</td>
            <td className="dobles">62 €</td>
            <td className="triples">77 €</td>
            <td className="cuadruples">88 €</td>
          </tr>
          <tr className="t-media">
            <td>Temporada Media</td>
            <td className="dobles">68 €</td>
            <td className="triples">84 €</td>
            <td className="cuadruples">98 €</td>
          </tr>
          <tr className="t-alta">
            <td>Temporada Alta</td>
            <td className="dobles">75 €</td>
            <td className="triples">90 €</td>
            <td className="cuadruples">105 €</td>
          </tr>
        </table>
        <p className="temporadas">
          <span>
            <span className="t-alta"> * Temporada alta:</span> Semana Santa y 20
            Diciembre - 7 Enero.
          </span>
          <span>
            <span className="t-media">Temporada media: </span>15 de junio a 15
            septiembre.
          </span>
          <span>
            <span className=" t-baja"> Temporada baja: </span> Resto del año.
          </span>
          <span>
            <span className=" "> Suplemento mascotas: </span> 10€.
          </span>
        </p>
        <p></p>

        <div className="habitaciones-wrapper">
          {habitaciones.map((habitacion) => (
            <RoomCard habitacion={habitacion}></RoomCard>
          ))}
        </div>
      </section>

      <Footer slim />
    </SSRProvider>
  );
};

export default Servicios;
