import { Link } from "gatsby";
import * as React from "react";
import "./roomCard.css";
const RoomCard = ({ habitacion }) => {

  return (
    <Link to={`/detalle?${habitacion.title}`} state={{ data: habitacion }}>
      <div className="room-card">
        <div className="room-image">
          <img src={habitacion.image} />
        </div>
        <div className="room-name">{habitacion.title}</div>
        <div
          className="room-description"
          dangerouslySetInnerHTML={{ __html: habitacion.cardDescripcion }}
        />
      </div>
    </Link>
  );
};

export default RoomCard;
