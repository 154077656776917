import enebro from "./images/HABITACIONES/Enebro/portada.jpg";
import enebro1 from "./images/HABITACIONES/Enebro/1.jpg";
import enebro2 from "./images/HABITACIONES/Enebro/2.jpg";
import enebro3 from "./images/HABITACIONES/Enebro/3.jpg";
import enebro4 from "./images/HABITACIONES/Enebro/4.jpg";
import enebro5 from "./images/HABITACIONES/Enebro/5.jpg";
import enebro6 from "./images/HABITACIONES/Enebro/6.jpg";
import enebro7 from "./images/HABITACIONES/Enebro/7.jpg";

import encina from "./images/HABITACIONES/Encina/portada.jpg";
import encina1 from "./images/HABITACIONES/Encina/1.jpg";
import encina2 from "./images/HABITACIONES/Encina/2.jpg";
import encina3 from "./images/HABITACIONES/Encina/3.jpg";
import encina4 from "./images/HABITACIONES/Encina/4.jpg";
import encina5 from "./images/HABITACIONES/Encina/5.jpg";
import encina6 from "./images/HABITACIONES/Encina/6.jpg";
import encina7 from "./images/HABITACIONES/Encina/7.jpg";

import higuera from "./images/HABITACIONES/HIGUERA/portada.jpg";
import higuera1 from "./images/HABITACIONES/HIGUERA/1.jpg";
import higuera2 from "./images/HABITACIONES/HIGUERA/2.jpg";
import higuera3 from "./images/HABITACIONES/HIGUERA/3.jpg";
import higuera4 from "./images/HABITACIONES/HIGUERA/4.jpg";
import higuera5 from "./images/HABITACIONES/HIGUERA/5.jpg";
import higuera6 from "./images/HABITACIONES/HIGUERA/6.jpg";
import higuera7 from "./images/HABITACIONES/HIGUERA/7.jpg";

import granado from "./images/HABITACIONES/GRANADO/portada.jpg";
import granado1 from "./images/HABITACIONES/GRANADO/1.jpg";
import granado2 from "./images/HABITACIONES/GRANADO/2.jpg";
import granado3 from "./images/HABITACIONES/GRANADO/3.jpg";
import granado4 from "./images/HABITACIONES/GRANADO/4.jpg";
import granado5 from "./images/HABITACIONES/GRANADO/5.jpg";
import granado6 from "./images/HABITACIONES/GRANADO/6.jpg";
import granado7 from "./images/HABITACIONES/GRANADO/7.jpg";

import fresno from "./images/HABITACIONES/FRESNO/portada.jpg";
import fresno1 from "./images/HABITACIONES/FRESNO/1.jpg";
import fresno2 from "./images/HABITACIONES/FRESNO/2.jpg";
import fresno3 from "./images/HABITACIONES/FRESNO/3.jpg";
import fresno4 from "./images/HABITACIONES/FRESNO/4.jpg";
import fresno5 from "./images/HABITACIONES/FRESNO/5.jpg";
import fresno6 from "./images/HABITACIONES/FRESNO/6.jpg";
import fresno7 from "./images/HABITACIONES/FRESNO/7.jpg";

import pino from "./images/HABITACIONES/PINO/portada.jpg";
import pino1 from "./images/HABITACIONES/PINO/1.jpg";
import pino2 from "./images/HABITACIONES/PINO/2.jpg";
import pino3 from "./images/HABITACIONES/PINO/3.jpg";
import pino4 from "./images/HABITACIONES/PINO/4.jpg";
import pino5 from "./images/HABITACIONES/PINO/5.jpg";
import pino6 from "./images/HABITACIONES/PINO/6.jpg";
import pino7 from "./images/HABITACIONES/PINO/7.jpg";

import sauco from "./images/HABITACIONES/SAUCO/portada.jpg";
import sauco1 from "./images/HABITACIONES/SAUCO/1.jpg";
import sauco2 from "./images/HABITACIONES/SAUCO/2.jpg";
import sauco3 from "./images/HABITACIONES/SAUCO/3.jpg";
import sauco4 from "./images/HABITACIONES/SAUCO/4.jpg";
import sauco5 from "./images/HABITACIONES/SAUCO/5.jpg";
import sauco6 from "./images/HABITACIONES/SAUCO/6.jpg";
import sauco7 from "./images/HABITACIONES/SAUCO/7.jpg";

import madroño from "./images/HABITACIONES/MADROÑO/portada.jpg";
import madroño1 from "./images/HABITACIONES/MADROÑO/1.jpg";
import madroño2 from "./images/HABITACIONES/MADROÑO/2.jpg";
import madroño3 from "./images/HABITACIONES/MADROÑO/3.jpg";
import madroño4 from "./images/HABITACIONES/MADROÑO/4.jpg";
import madroño5 from "./images/HABITACIONES/MADROÑO/5.jpg";
import madroño6 from "./images/HABITACIONES/MADROÑO/6.jpg";
import madroño7 from "./images/HABITACIONES/MADROÑO/7.jpg";

export const habitaciones = [
  {
    description:
      "<p>Una habitación que dispone de una cama de matrimonio, baño privado y vistas a la piscina y al campo que rodea la posada.</p><p> Se encuentra en el patio interior de la casa, una habitación perfecta para disfrutar en pareja o para estar una persona sola y disfrutar de la tranquilidad.</p>",
    title: "Enebro",
    image: enebro,
    cardDescripcion: "Habitación doble con vistas a la piscina.",
    imagesArray: [
      enebro1,
      enebro2,
      enebro3,
      enebro4,
      enebro5,
      enebro6,
      enebro7,
    ],
  },
  {
    description:
      "<p>Una habitación que dispone dos camas individuales, baño privado, butaca, escritorio y terraza con vistas al patio de entrada de la casa. al pueblo y al campo.</p><p> Se encuentra en el interior casa, una habitación perfecta para pasar un buen fin de semana de tranquilidad.</p>",
    title: "Higuera",
    image: higuera,
    cardDescripcion: "Habitación doble con terraza y vistas al patio.",
    imagesArray: [
      higuera1,
      higuera2,
      higuera3,
      higuera4,
      higuera5,
      higuera6,
      higuera7,
    ],
  },
  {
    description:
      "Una habitación que dispone de una cama de matrimonio, una cama individual, baño privado y chimenea. </p><p>Se encuentra en el patio principal de la casa, una habitación acogedora ,perfecta para disfrutar en familia.</p>",
    title: "Granado",
    cardDescripcion: "Habitación triple con chimenea.",

    image: granado,
    imagesArray: [
      granado1,
      granado2,
      granado3,
      granado4,
      granado5,
      granado6,
      granado7,
    ],
  },
  {
    description:
      "Una habitación que dispone de tres camas individuales, baño privado y una terraza con vistas al pueblo y al campo. </p><p>Perfecta para pasar una estancia en un lugar acogedor, situada en la planta superior de nuestra posada.</p>",
    title: "Encina",
    image: encina,
    cardDescripcion: "Habitación triple con terraza y vistas al pueblo.",
    imagesArray: [
      encina1,
      encina2,
      encina3,
      encina4,
      encina5,
      encina6,
      encina7,
    ],
  },
  {
    description:
      "Una habitación que dispone tres camas individuales, baño privado y terraza con vistas al patio de entrada de la casa pueblo y al campo.</p><p> Se encuentra en el interior casa, más concretamente en la planta superior. Una habitación acogedora con techo abuhardillado para sentirte en un ambiente acogedor.</p>",
    title: "Pino",
    image: pino,
    cardDescripcion: "Habitación triple con terraza y vistas al patio.",
    imagesArray: [pino1, pino2, pino3, pino4, pino5, pino6, pino7],
  },
  {
    description:
      "Una habitación amplia, con chimenea, una cama de matrimonio, dos camas individuales y baño privado.</p><p> Muy amplia, luminosa y situada en el patio exterior, con vistas a la piscina.</p>",
    title: "Sauco",
    image: sauco,
    cardDescripcion: "Habitación cuádruple con chimenea y vistas a la piscina",
    imagesArray: [
      sauco1,
      sauco2,
      sauco3,
      sauco4,
      sauco5,
      sauco6,
      sauco7,
    ],
  },
  {
    description:
      "Una habitación que dispone dos camas individuales, una cama de matrimonio, baño privado y chimenea.</p><p> Se encuentra en el patio principal de la casa, perfecta para hospedarse en familia y disfrutar de un lugar acogedor.</p>",
    title: "Madroño",
    image: madroño,
    cardDescripcion: "Habitación cuádruple con chimena.",
    imagesArray: [
      madroño1,
      madroño2,
      madroño3,
      madroño4,
      madroño5,
      madroño6,
      madroño7,
    ],
  },
  {
    description:
      "Una habitación que dispone de tres camas individuales, baño privado y terraza con vistas al campo y al pueblo.</p><p> Se encuentra en la planta superior de la casa, una habitación perfecta para disfrutar en pareja o para una familia pequeña.</p>",
    title: "Fresno",
    image: fresno,
    cardDescripcion: "Habitación triple con terraza y vistas al pueblo.",
    imagesArray: [
      fresno1,
      fresno2,
      fresno3,
      fresno4,
      fresno5,
      fresno6,
      fresno7,
    ],
  },
];
